window.addEventListener("DOMContentLoaded", () => {
    console.log(':)');

    try {

        let faqItems = document.querySelectorAll("#faq .item");

        faqItems.forEach((item) => {
           item.addEventListener("click", (e) => {
               //e.preventDefault();

               // faqItems.forEach((i) => {
               //     i.classList.remove("active");
               //     console.log("remove all");
               // });

               if ( item.classList.contains("active") ) {
                   item.classList.remove("active");
               } else {
                   item.classList.add("active");
               }

           })
        });

    } catch (e) {
        console.log(e + "faq");
    }

    try {
        let burger = document.querySelector("#burger");
        let headerNavs = document.querySelector(".header__navs");
        burger.addEventListener("click", (e) => {
            e.preventDefault();
            burger.classList.toggle("show");
            headerNavs.classList.toggle("show");
        })
    } catch (e) {
        console.log(e + "header");
    }



    try {
        const anchors = document.querySelectorAll(".anchors");
        anchors.forEach((item) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();
                const blockID = item.getAttribute('href').substr(1);
                document.getElementById(blockID).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
                anchors.forEach((i) => {
                    i.classList.remove("active");
                });
                item.classList.add("active");
            })
        });
    }catch(e){
        console.log(e + "якоря");
    }

});